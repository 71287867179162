import React, { useEffect, useState } from 'react'
import StreamNextSong from './StreamNextSong'

function StreamSlideAnimation({ configurationRadio, Lyrics, lyricsAllowed, lastAlbumCover, defaultSleeve, logoRadio, lastTitle, lastArtiste, nextTitle, rotateLogo }) {

    const [showAnime, setShowAnime] = useState(1);

    useEffect(() => {
        setTimeout(() => {
            setShowAnime(showAnime + 1);
        }, 75000);

    }, [showAnime])

    return (
        <>
            {((lastTitle || lastArtiste) && Lyrics.length === 0) && <div style={{
                position: 'absolute',
                zIndex: 3,
                top: configurationRadio?.walletPosition.includes('top') && '30px',
                left: configurationRadio?.walletPosition.includes('left') && '30px',
                bottom: configurationRadio?.walletPosition.includes('bottom') && '30px',
                right: configurationRadio?.walletPosition.includes('right') && '30px',
                width: '50%',
                height: configurationRadio?.walletH,
                display: 'flex',
                flexDirection: 'column',
                gap: 20
            }}>

                <div className='song-details' style={{
                    height: "100%"
                }}>
                    <div className='song-image' style={{
                        height: "100%",
                        width: configurationRadio?.walletW
                    }}>
                        <img className='' alt='' src={lastAlbumCover !== "" ? lastAlbumCover : (defaultSleeve !== "" ? defaultSleeve : logoRadio)} style={{ height: configurationRadio?.walletH, width: configurationRadio?.walletW, borderRadius: 10 }} />
                    </div>

                    <div style={{
                        position: 'relative',
                        width: `100%`,
                        height: '100%',
                        left: "15px"
                    }}>
                        <div className={`song-title-${configurationRadio?.textEffect}`} style={{
                            position: 'absolute',
                            // top: configurationRadio?.titlePosition.includes('top') && 0,
                            left: configurationRadio?.titlePosition.includes('left') && 0,
                            bottom: configurationRadio?.titlePosition.includes('bottom') && 0,
                            right: configurationRadio?.titlePosition.includes('right') && 0,
                            transform: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && 'translateY(-50%)',
                            top: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && '50%',
                        }}>
                            <h1 style={{
                                color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.titleColor : 'transparent',
                                fontSize: configurationRadio?.titleSize,
                                fontFamily: configurationRadio?.titlePolice,
                                WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.titleColor}`,
                            }}>{configurationRadio?.positionInversion ? lastTitle : lastArtiste}</h1>

                            <span style={{
                                color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                                fontSize: configurationRadio?.artistSize,
                                fontFamily: configurationRadio?.artistPolice,
                                WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                            }}>{configurationRadio?.positionInversion ? lastArtiste : lastTitle}</span>
                        </div>
                    </div>

                </div>

                {/* <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 11,
                        fontWeight: 900,
                        fontFamily:'arial black'
                    }}>Titre suivant</span>

                    <span style={{
                        color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                        fontSize: configurationRadio?.artistSize,
                        fontFamily: configurationRadio?.artistPolice,
                        WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                    }}>{nextTitle?.artist?.length > 20 ? `${nextTitle?.artist.substring(0, 20)}...` : nextTitle?.artist} - {nextTitle?.titre?.length > 25 ? `${nextTitle?.titre.substring(0, 25)}...` : nextTitle?.titre}</span>
                </div> */}

            </div>}
            <StreamNextSong key={showAnime} nextTitle={nextTitle} configurationRadio={configurationRadio} logoRadio={logoRadio} />



            <div className='logo' style={{
                zIndex: 3,
                position: 'absolute',
                top: configurationRadio?.logoPosition.includes('top') && '30px',
                left: configurationRadio?.logoPosition.includes('left') && '30px',
                bottom: configurationRadio?.logoPosition.includes('bottom') && '30px',
                right: configurationRadio?.logoPosition.includes('right') && '30px',
                height: configurationRadio?.logoH,
                width: configurationRadio?.logoW,
            }}>
                <img
                    alt=''
                    className={`logo ${rotateLogo === true ? "animation-disk" : ""} `} src={logoRadio} style={{ height: '100%', width: '100%', borderRadius: 10 }}
                />
            </div>


            <div style={{
                position: 'absolute',
                zIndex: 3,
                right: "1%",
                bottom: (Lyrics.length && lyricsAllowed) ? `${configurationRadio?.lyricsFilterSize + 2}%` : '1%',
            }}>

            </div>
        </>
    )
}

export default StreamSlideAnimation