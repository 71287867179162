import VideoPlayerWithOverlay from "./VideoPlayerWithOverlay"
import { useParams } from 'react-router-dom'

const GlobalPlayer = () => {
    const { videoId, playerId } = useParams()

    return (
        <div className="w-full h-screen">
            <VideoPlayerWithOverlay
                videoId={videoId}
                overlayImage="
                https://wallpaperswide.com/download/street_advertising_in_new_york-wallpaper-1920x1080.jpg
                "
                intervalDuration={15000}
                playerId={playerId}
            />
        </div>
    )
}

export default GlobalPlayer