import React, { useState } from 'react'
import {
    Card, CardBody, ModalBody,
    Col, Modal, ModalFooter, ModalHeader, PopoverBody, Row, Table, Media
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux';
import { addRadioFailure, addRadioRequest, addRadioSuccess, fetchRadioFailure, fetchRadioRequest, fetchRadioSuccess } from '../../store/actions/radioActions';
import axiosClient from '../../../axios-client';
import { useEffect } from 'react';
import Lottie from 'react-lottie';
import loadData from '../../../assets/lottie/laod.json'
import { Avatar, Divider, Pagination, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { fetchUsersFailure, fetchUsersRequest, fetchUsersSuccess } from '../../store/actions/userActions';
import CopyToClipboard from 'react-copy-to-clipboard';
import { MdOutlineContentCopy } from "react-icons/md";
import { IoCheckmarkDoneSharp, IoTrash } from "react-icons/io5";
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { RiImageAddFill } from "react-icons/ri";
import { Button } from 'react-bootstrap';
import { Bounce, ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../../../context/ContextProvider';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { FaRadio } from "react-icons/fa6";
import { CiStreamOn } from "react-icons/ci";


import randomstring from 'randomstring'
import CryptoJS from 'crypto-js';


export default function Radios() {
    const [nameMessageError, setNameMessageError] = useState(null); // Initialize nameMessage state variable
    const { user, setToken, setUser, setRefresh, token } = useStateContext();

    const [tagMessageError, setTagMessageError] = useState(null); // Initialize nameMessage state variable

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const [changeModal, setChangeModal] = useState(true)

    const [copied, setCopied] = useState(false);
    const [indexLinkCopied, setIndexLinkCopied] = useState(-1)
    const { users } = useSelector(state => state.users);

    const [radioData, setRadioData] = useState([]);
    const [radioId, setRadioId] = useState([]);

    const [image, setImage] = useState()

    const [selectedImage, setSelectedImage] = useState()
    // const [selectedImg, setSelectedImg] = useState()

    const [instance, setInstance] = useState()
    const [instanceSimbals, setInstanceSimbals] = useState()

    const [allowLyrics, setAllowLyrics] = useState(false);

    const [logoBehavior, setLogoBehavior] = useState(false);


    const [tagRadio, setTagRadio] = useState()
    const [tagSimbals, setTagSimbals] = useState()

    const [selectedUser, setSelectedUser] = useState();
    const [audioLinkStream, setAudioLinkStream] = useState()
    const [name, setName] = useState()

    const [message, setMessage] = useState('');

    const prefix = randomstring.generate({ length: 7, capitalization: 'uppercase' })
    const suffix = randomstring.generate({ length: 7, capitalization: 'uppercase' })

    console.log(prefix + 'ok' + suffix);



    console.log("selectedUser", selectedUser);
    const [lengthProhibited, setLengthProhibited] = useState(false)


    const notifyError = (text) => toast.error(text, {
        position: "top-center",
        autoClose: 400,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
    });

    useEffect(() => {
        dispatch(fetchUsersRequest());

        const fetchAllUsers = async () => {
            let nextPage = 'users/'; // Start with the initial endpoint
            let allUsers = [];

            try {
                while (nextPage) {
                    const response = await axiosClient.get(nextPage);
                    allUsers = [...allUsers, ...response.data.results];
                    nextPage = response.data.next; // Update to the next page URL or null if no more pages
                }

                dispatch(fetchUsersSuccess(allUsers));
            } catch (error) {
                dispatch(fetchUsersFailure(error.message));
            }
        };

        fetchAllUsers();
    }, [dispatch]);


    const [modal_center, setmodal_center] = useState(false)
    function removeBodyCss() {
        document.body.classList.add("no_padding")
    }

    function tog_center() {
        setmodal_center(!modal_center)
        removeBodyCss()
        setSelectedImage()
        // setSelectedImg()
        setImage()
        setName()
        setAudioLinkStream()
        setInstance()
        setInstanceSimbals()
        setSelectedUser()
        setTagRadio()
        setTagSimbals()
        setMessage()
    }



    const { loading, radios, error } = useSelector(state => state.radios);
    const [page, setPage] = useState(1); // State for current page
    const [totalPages, setTotalPages] = useState(1); // State for total pages

    useEffect(() => {
        dispatch(fetchRadioRequest());

        // Check if user is defined before accessing its properties
        const userId = user?.id;

        // If user is undefined, handle the case gracefully
        if (userId === undefined) {
            // Handle the case where user is undefined (e.g., show a message or handle the error)
            return;
        }

        axiosClient.get(`v_radios/?page=${page}&user=${userId}`)
            .then(response => {
                dispatch(fetchRadioSuccess(response.data));
                setTotalPages(Math.ceil(response.data.count / 10));
            })
            .catch(error => {
                dispatch(fetchRadioFailure(error.message));
            });
    }, [dispatch, page, user]);





    const [showLoading, setShowLoading] = useState(false);


    useEffect(() => {
        if (selectedImage) {
            setImage(URL.createObjectURL(selectedImage))
        }
    }, [selectedImage])

    if (loading) {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: loadData,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <Lottie options={defaultOptions} height={120} width={120} />
            </div>
        );
    }
    if (error) {
        return <div>Error: {error}</div>;
    }

    // const formData = new FormData(); // Create a new FormData object

    // // Append form data fields
    // formData.append('name', name);
    // formData.append('audioLinkStream', audioLinkStream);
    // if (selectedImage) formData.append('logo', selectedImage);
    // formData.append('user', selectedUser); // Append the logo file
    // formData.append('instance', instance);
    // formData.append('tagRadio', tagRadio);
    // formData.append('tagSimbals', tagSimbals);



    const handlePageChange = (event, value) => {
        setPage(value); // Update page state when page changes
    };
    const checkNameAvailability = async (name) => {
        try {
            const response = await axiosClient.get(`check_name_availability/?name=${name}`);
            const responseData = response.data;

            if ('message' in responseData) {
                if (responseData.message === "Radio with this name already exists.") {
                    return false; // Name is not available
                } else if (responseData.message === "name is available.") {
                    return true; // Name is available
                } else {
                    return "Unknown response from server"; // Handle unexpected message
                }
            } else {
                return "Unknown response from server"; // Handle unexpected response
            }
        } catch (error) {
            console.error("Error checking name availability:", error);
            return "Error checking name availability.";
        }
    };

    const checkTagAvailability = async (tagRadio) => {
        try {
            const response = await axiosClient.get(`check_tag_availability/?tagRadio=${tagRadio}`);
            const responseData = response.data;

            if ('message' in responseData) {
                if (responseData.message === "Radio with this tag already exists.") {
                    return false; // Tag is not available
                } else if (responseData.message === "Tag is available.") {
                    return true; // Tag is available
                } else {
                    return "Unknown response from server"; // Handle unexpected message
                }
            } else {
                return "Unknown response from server"; // Handle unexpected response
            }
        } catch (error) {
            console.error("Error checking tag availability:", error);
            return "Error checking tag availability.";
        }
    };



    const handleSubmit = async () => {
        console.log("nmxxxxxxxx", selectedUser, instance, tagRadio);



        console.log("the  image", selectedImage)

        if (selectedImage && selectedImage.name.length > 100) {
            notifyError('La taille de l\'image  ne doit pas dépasser 100 caractères.');
            // setLengthProhibited(true);
            return;
        } else {

            // setLengthProhibited(false);
        }
        // Check if the name already exists
        const nameMessage = await checkNameAvailability(name);
        if (nameMessage === false) {
            setNameMessageError("Une radio avec le même nom existe déjà.");
        } else {
            setNameMessageError(""); // Clear the error message if name is available
        }

        // Check if the tag already exists
        const tagMessage = await checkTagAvailability(tagRadio);
        if (tagMessage === false) {
            setTagMessageError("Une radio avec le même tag existe déjà.");
        } else {
            setTagMessageError(""); // Clear the error message if tag is available
        }

        // If either name or tag already exists, prevent form submission
        if (nameMessage === false || tagMessage === false) {
            return;
        }

        console.log("sss user", selectedUser);

        console.log("nameMessage", nameMessage);
        console.log("tagMessage", tagMessage);

        // Validate if all fields are filled
        if (!name || !audioLinkStream || !selectedImage || !instance || !tagRadio || !instanceSimbals) {
            setMessage("Veuillez remplir tous les champs!");
            return;
        }

        // Ensure that selectedUser is set based on the user's role
        const validatedUser = user && user.role === "RADIOOWNER" ? user.id : selectedUser;

        // Create a FormData object to send the form data
        const formData = new FormData();
        formData.append('name', name);
        formData.append('audioLinkStream', audioLinkStream);
        formData.append('user', validatedUser);
        formData.append('tagRadio', tagRadio);
        formData.append('tagSimbals', tagSimbals);
        formData.append('instance', instance);
        formData.append('instanceSimbals', instanceSimbals);

        formData.append('allowLyrics', allowLyrics);



        formData.append('logoBehavior', logoBehavior);


        if (selectedImage) {

            formData.append('logo', selectedImage);
        }

        // Dispatch an action to start loading state
        dispatch(addRadioRequest(formData));

        // Send a POST request to add the radio
        axiosClient.post('radios/', formData)
            .then(response => {

                toast.success("La radio a été ajoutée avec succès", {
                    position: "top-center",
                    autoclose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                // Handle success
                console.log('Radio added successfully:', response.data);
                setmodal_center(false); // Close the modal
                dispatch(addRadioSuccess()); // Update loading state to false

                // Fetch updated radio data for the current page
                axiosClient.get(`radios/?page=${page}`)
                    .then(response => {
                        dispatch(fetchRadioSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / 10)); // Update total pages after adding a radio
                    })
                    .catch(error => {
                        dispatch(fetchRadioFailure(error.message));
                    });
            })
            .catch(error => {
                // Log and handle error
                console.error('Error adding radio:', error);
                if (error.response) {
                    console.error('Error response status:', error.response.status);
                    console.error('Error response data:', error.response.data);
                    setMessage(error.response.data.error || 'Error adding radio');
                } else if (error.request) {
                    console.error('No response received:', error.request);
                    setMessage('No response received from the server.');
                } else {
                    console.error('Request setup error:', error.message);
                    setMessage('Error setting up request.');
                }

                // Handle error and update loading state
                dispatch(addRadioFailure(error.message));
            });
    };





    const handleEditSubmit = async (id) => {


        const formDataEdit = new FormData();

        if (name) formDataEdit.append('name', name);
        if (audioLinkStream) formDataEdit.append('audioLinkStream', audioLinkStream);
        if (selectedImage) {
            console.log('====================================');
            console.log("selected image check: ", selectedImage);
            console.log('====================================');
            formDataEdit.append('logo', selectedImage);
        }
        if (selectedUser) {
            // If the user's role is RADIOOWNER, send user.id; otherwise, send selectedUser
            const userIdToSend = user && user.role === 'RADIOOWNER' ? user.id : selectedUser;
            formDataEdit.append('user', userIdToSend);
        }
        if (instance) formDataEdit.append('instance', instance);
        if (instanceSimbals) formDataEdit.append('instanceSimbals', instanceSimbals);
        if (tagRadio) formDataEdit.append('tagRadio', tagRadio);

        if (tagSimbals) formDataEdit.append('tagSimbals', tagSimbals);


        formDataEdit.append('allowLyrics', allowLyrics);


        formDataEdit.append('logoBehavior', logoBehavior);

        const nameMessage = await checkNameAvailability(name);
        if (nameMessage === false) {
            setNameMessageError("Une radio avec le même nom existe déjà.");
        } else {
            setNameMessageError(""); // Clear the error message if name is available
        }
        if (selectedImage && selectedImage.name.length > 100) {
            notifyError('La taille de l\'image  ne doit pas dépasser 100 caractères.');
            // setLengthProhibited(true);
            return;
        } else {

            // setLengthProhibited(false);
        }
        // Check if the tag already exists
        const tagMessage = await checkTagAvailability(tagRadio);
        if (tagMessage === false) {
            setTagMessageError("Une radio avec le même tag existe déjà.");
        } else {
            setTagMessageError(""); // Clear the error message if tag is available
        }

        // If either name or tag already exists, prevent form submission
        if (nameMessage === false || tagMessage === false) {
            return;
        }


        axiosClient.patch(`radios/${id}/`, formDataEdit)
            .then(response => {
                // Handle success
                setmodal_center(false); // Close the modal
                axiosClient.get(`radios/?page=${page}`) // Fetch updated radio data for the current page
                    .then(response => {
                        toast.success("La radio a été modifiée avec succès", {
                            position: "top-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                        });
                        dispatch(fetchRadioSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / 10)); // Update total pages after adding a radio
                    })
                    .catch(error => {
                        console.log(error)
                    });
            })
            .catch(error => {
                console.error('Error editing radio:', error);
                if (error.response) {
                    console.error('Error response status:', error.response.status);
                    console.error('Error response data:', error.response.data);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Request setup error:', error.message);
                }

            });

    }


    const handleCopy = (index) => {
        setCopied(true);
        setIndexLinkCopied(index)
        setTimeout(() => {
            setCopied(false)
            setIndexLinkCopied(-1)
        }, 2000);
    };

    const handleDelete = (id) => {
        if (!id) return;
        axiosClient.delete(`/radios/${id}/`)
            .then((response) => {
                axiosClient.get(`radios/?page=${page}`) // Fetch updated radio data for the current page
                    .then(response => {
                        dispatch(fetchRadioSuccess(response.data));
                        setTotalPages(Math.ceil(response.data.count / 10)); // Update total pages after adding a radio
                    })
                    .catch(error => {
                        console.log(error)
                    });
                toast.error("La radio est supprimée avec succès", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                });
                toggle()
                window.scrollTo(0, 0)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleAllowLyricsChange = () => {
        setAllowLyrics(!allowLyrics);
    };



    // console.log("nfff", nameMessage);
    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={400}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition="bounce"
            />            <Row className="icons-demo-content">
                <Col xs="12">

                    <Card>
                        <CardBody>
                            <h4 className="card-title" style={{ fontWeight: 'bold' }}>Gestion Radios</h4>

                            <Row className="mb-3">
                                <Col lg={12} className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            tog_center();
                                            setChangeModal(true)
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-radio" />
                                        Ajouter une radio
                                    </button>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={12}>
                                    <div className="card">
                                        <div className="card-body">

                                            <div style={{ maxHeight: "450px", overflowY: "auto" }} className="table-responsive">
                                                <Table responsive className="table-primary table-striped mb-0">

                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Radio</th>
                                                            <th>Tag</th>
                                                            <th>Simbals</th>

                                                            <th>Instance</th>
                                                            <th>Instance Simbals</th>

                                                            <th>URL</th>
                                                            <th>Image</th>
                                                            <th>Date création</th>
                                                            <th>Radio display</th>
                                                            <th>Options</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {radios && radios.results && radios.results.map(radio => (
                                                            <tr styl key={radio.id}>
                                                                <th style={{ alignContent: 'center' }} scope="row">{radio.id}</th>
                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }} > {radio.name}</td>
                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }} > {radio.tagRadio}</td>
                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }} > {radio.tagSimbals}</td>

                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }} > {radio.instance === 0 && "Radio Screen 01"} {radio.instance === 1 && "Radio Screen 02"}</td>
                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }} > {radio.instanceSimbals === 0 && "Radio Screen 01"} {radio.instanceSimbals === 1 && "Radio Screen 02"}</td>

                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>
                                                                    <div style={{ display: "flex", height: '100%' }}>
                                                                        <a href={radio.audioLinkStream}>{radio.audioLinkStream.length > 30 ? `${radio.audioLinkStream.substring(0, 30)}...` : radio.audioLinkStream}</a>
                                                                        <OverlayTrigger overlay={<Tooltip className='' id="tooltip-disabled">{(copied && indexLinkCopied === radio.id) ? "copié!" : "copier"}</Tooltip>}>
                                                                            <CopyToClipboard text={radio.audioLinkStream} onCopy={() => handleCopy(radio.id)}>

                                                                                {(copied && indexLinkCopied === radio.id) ?
                                                                                    <div>
                                                                                        <IoCheckmarkDoneSharp style={{ marginLeft: 5 }} className='ml-5' size={18} />
                                                                                    </div>
                                                                                    :
                                                                                    <MdOutlineContentCopy style={{ marginLeft: 5 }} cursor={"pointer"} size={18} />
                                                                                }

                                                                            </CopyToClipboard>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </td>

                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>

                                                                    {/* <CardImg style={{ height: "50px", width: "50px" }} top className="img-fluid" src={radio.logo} alt="logo" /> */}
                                                                    <Avatar alt="Radio logo v1.1" src={radio.logo} />


                                                                </td>
                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>{new Date(radio.date_creation).toLocaleDateString()}</td>



                                                                <td style={{ alignContent: 'center', justifyContent: "center", height: "100%" }}>
                                                                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: "space-between", height: "100%" }}>
                                                                        <div>
                                                                            <CiStreamOn
                                                                                style={{ fontSize: "22px", cursor: "pointer" }}
                                                                                onClick={() => window.open(`/audiostream/${CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(radio.id.toString(), 'secret-key').toString()))}`, '_blank')}
                                                                            // onClick={() => window.location.href = `/audiostream/${radio.id}`}

                                                                            />
                                                                        </div>

                                                                        <div>    <FaRadio
                                                                            style={{ fontSize: "22px", cursor: "pointer" }}
                                                                            // onClick={() => window.open(`/home/${radio.id}`, '_blank')}
                                                                            onClick={() => {
                                                                                console.log('ID RADIOOOOO : ', radio.id);
                                                                                window.location.href = `/home/?r=${CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(CryptoJS.AES.encrypt(radio.id.toString(), 'secret-key').toString()))}`
                                                                            }}

                                                                        />
                                                                        </div>



                                                                    </div>
                                                                </td>
                                                                <td style={{ alignContent: 'center', textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap" }}>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setChangeModal(false)
                                                                            tog_center();
                                                                            setAllowLyrics(radio.allowLyrics)
                                                                            setLogoBehavior(radio.logoBehavior)

                                                                            setRadioData(radio)
                                                                            setRadioId(radio.id);
                                                                        }}
                                                                        variant="outline-primary">Modifier
                                                                    </Button>{' '}
                                                                    <Button
                                                                        onClick={() => {
                                                                            toggle()

                                                                            setRadioId(radio.id);
                                                                        }}
                                                                        variant='danger'
                                                                    >
                                                                        <IoTrash fontSize={'18px'} />
                                                                    </Button>{' '}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>

                                        </div>
                                    </div>
                                </Col>


                            </Row>
                            <Row>
                                <Col lg={12} className="d-flex justify-content-end">
                                    <Stack spacing={2}>
                                        <Pagination count={totalPages} color="secondary" page={page} onChange={handlePageChange} />
                                    </Stack>
                                </Col>
                            </Row>

                        </CardBody>
                        {changeModal ?
                            <Modal
                                size='lg'
                                isOpen={modal_center}

                                toggle={() => {
                                    tog_center()

                                }}
                                centered={true}
                            >


                                <ModalHeader style={{ fontSize: "20px" }} toggle={() => {
                                    tog_center()
                                    // setSelectedImage()
                                    // setSelectedImg()
                                    // setImage()
                                }}>
                                    <h3>Ajouter une radio</h3>


                                </ModalHeader>

                                <ModalBody className='p-4 mx-2'>


                                    <form encType="multipart/form-data">
                                        <Row>
                                            <Col style={{ alignContent: "center" }} lg="3">

                                                <Media className='mb-3' style={{ justifyContent: 'center', alignItems: "center" }}>
                                                    <div className="position-relative" style={{ width: 150, height: 150 }}>
                                                        <Avatar
                                                            style={{ objectFit: "cover", borderRadius: "5%" }}
                                                            sx={{ width: 150, height: 150 }}
                                                            src={image}
                                                            variant="square"
                                                        />
                                                        <label htmlFor="imageInput" className="position-absolute" style={{
                                                            cursor: 'pointer',
                                                            bottom: -15,
                                                            right: -10,
                                                            border: "3px solid white",
                                                            borderRadius: "50%"
                                                        }}>
                                                            <input
                                                                id="imageInput"
                                                                type="file"
                                                                name='logo'
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => {
                                                                    // handleFileChange(e)
                                                                    setSelectedImage(e.target.files[0])
                                                                }
                                                                }
                                                            />
                                                            <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                                                                <RiImageAddFill size={15} color='#fff' />
                                                            </div>
                                                        </label>
                                                    </div>

                                                </Media>
                                            </Col>
                                            <Col style={{ display: 'flex', justifyContent: "center" }} lg="1">
                                                <Divider style={{ borderColor: "gray" }} orientation="vertical" variant="middle" />
                                            </Col>


                                            <Col lg="8">

                                                {message &&
                                                    <Alert variant={"danger"}>
                                                        {message}
                                                    </Alert>
                                                }
                                                <Row className="mb-3">
                                                    <label htmlFor="name" className="col-md-3 col-form-label">Nom Radio</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            style={{ border: nameMessageError ? '2px solid red' : '' }}
                                                            className="form-control "
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            value={name}
                                                            onChange={e => setName(e.target.value)}
                                                        />
                                                        {nameMessageError &&
                                                            <p style={{ color: 'red' }}> {nameMessageError}</p>
                                                        }
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="URL" className="col-md-3 col-form-label">Url radio</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            className="form-control"
                                                            type="text"
                                                            id="URL"
                                                            name="audioLinkStream"
                                                            value={audioLinkStream}
                                                            onChange={e => setAudioLinkStream(e.target.value)}
                                                        />
                                                    </div>
                                                </Row>

                                                {/* <Row className="mb-3">
                                                    <label htmlFor="image" className="col-md-3 col-form-label">Image radio</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required
                                                            className="form-control"
                                                            type="file"
                                                            id="image"
                                                            name="logo"
                                                            onChange={handleFileChange}
                                                        />
                                                    </div>
                                                </Row> */}


                                                {user.role === "Manager" ? (<Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">User </label>
                                                    <div className="col-md-9">
                                                        <select
                                                            required
                                                            className="form-control"
                                                            id="userType"
                                                            name="user"
                                                            value={selectedUser}
                                                            onChange={e => setSelectedUser(e.target.value)}
                                                        >
                                                            <option value="">Select a user</option> {/* Optional default option */}
                                                            {users && Array.isArray(users) && users.map(user => (
                                                                <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </Row>) : (<p></p>)
                                                }
                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Tag radio </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            required

                                                            style={{ border: tagMessageError ? '2px solid red' : '' }}

                                                            className="form-control"
                                                            type="number"
                                                            id="image"
                                                            name="tagRadio"
                                                            value={tagRadio}
                                                            onChange={e => setTagRadio(e.target.value)}
                                                        />
                                                        {tagMessageError &&
                                                            <p style={{ color: 'red' }}> {tagMessageError}</p>
                                                        }
                                                    </div>
                                                </Row>


                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Simbals </label>
                                                    <div className="col-md-9">
                                                        <input


                                                            className="form-control"
                                                            type="number"
                                                            id="image"
                                                            name="tagSimbals"
                                                            value={tagSimbals}
                                                            onChange={e => setTagSimbals(e.target.value)}
                                                        />

                                                    </div>
                                                </Row>


                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Instance </label>
                                                    <div className="col-md-9">
                                                        <select
                                                            required
                                                            className="form-control"
                                                            id="userType"
                                                            name="instance"
                                                            value={instance}
                                                            onChange={e => setInstance(e.target.value)}
                                                        >
                                                            <option value="">Choisissez une instance</option>
                                                            <option value="0">Radio screen 01</option> {/* Optional default option */}
                                                            <option value="1">Radio screen 02</option> {/* Optional default option */}


                                                        </select>
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Instance Simbals </label>
                                                    <div className="col-md-9">
                                                        <select
                                                            required
                                                            className="form-control"
                                                            id="userType"
                                                            name="instanceSimbals"
                                                            value={instanceSimbals}
                                                            onChange={e => setInstanceSimbals(e.target.value)}
                                                        >
                                                            <option value="">Choisissez une instance</option>
                                                            <option value="0">Radio screen 01</option> {/* Optional default option */}
                                                            <option value="1">Radio screen 02</option> {/* Optional default option */}


                                                        </select>
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="allowLyrics" className="col-md-3 col-form-label">Allow Lyrics</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            style={{ marginTop: '8px' }}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="allowLyrics"
                                                            name="allowLyrics"
                                                            checked={allowLyrics}
                                                            onChange={e => setAllowLyrics(e.target.checked)}
                                                        />
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="logoBehavior" className="col-md-3 col-form-label">Tourner Logo</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            style={{ marginTop: '8px' }}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="logoBehavior"
                                                            name="logoBehavior"

                                                            checked={logoBehavior}
                                                            onChange={e => setLogoBehavior(e.target.checked)}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </form>
                                </ModalBody>



                                <ModalFooter>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-radio" />
                                        Ajouter une radio
                                    </button>
                                </ModalFooter>
                            </Modal>
                            :
                            <Modal
                                size='lg'
                                isOpen={modal_center}
                                toggle={() => {
                                    tog_center()
                                }}
                                centered={true}
                            >


                                <ModalHeader style={{ fontSize: "20px" }} toggle={() => {
                                    tog_center()
                                }}>
                                    <h3>Modifier la radio</h3>


                                </ModalHeader>

                                <ModalBody className='p-4 mx-2'>


                                    <form encType="multipart/form-data">

                                        <Row>
                                            <Col style={{ alignContent: "center" }} lg="3">

                                                <Media className='mb-3' style={{ justifyContent: 'center', alignItems: "center" }}>
                                                    <div className="position-relative" style={{ width: 150, height: 150 }}>
                                                        <Avatar
                                                            style={{ objectFit: "cover", borderRadius: "5%" }}
                                                            sx={{ width: 150, height: 150 }}
                                                            src={image ? image : radioData.logo}
                                                            variant="square"
                                                        />
                                                        <label htmlFor="imageInput" className="position-absolute" style={{
                                                            cursor: 'pointer',
                                                            bottom: -15,
                                                            right: -10,
                                                            border: "3px solid white",
                                                            borderRadius: "50%"
                                                        }}>

                                                            <input
                                                                id="imageInput"

                                                                type="file"
                                                                name='logo'
                                                                accept="image/*"
                                                                style={{ display: 'none' }}
                                                                onChange={(e) => {
                                                                    setSelectedImage(e.target.files[0])
                                                                }}
                                                            />
                                                            <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#232939', borderRadius: '50%', width: 30, height: 30 }}>
                                                                <FaPencilAlt size={15} color='#fff' />
                                                            </div>
                                                        </label>
                                                    </div>

                                                </Media>
                                            </Col>
                                            <Col style={{ display: 'flex', justifyContent: "center" }} lg="1">
                                                <Divider style={{ borderColor: "gray" }} orientation="vertical" variant="middle" />
                                            </Col>


                                            <Col lg="8">
                                                <Row className="mb-3">
                                                    <label htmlFor="name" className="col-md-3 col-form-label">Nom Radio</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            className="form-control "
                                                            type="text"
                                                            style={{ border: nameMessageError ? '2px solid red' : '', }}

                                                            id="name"
                                                            name="name"
                                                            defaultValue={radioData.name}
                                                            onChange={e => setName(e.target.value)}
                                                        />
                                                        {nameMessageError &&
                                                            <p style={{ color: 'red' }}> {nameMessageError}</p>
                                                        }

                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="URL" className="col-md-3 col-form-label">Url radio</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="URL"
                                                            name="audioLinkStream"
                                                            defaultValue={radioData.audioLinkStream}
                                                            onChange={e => setAudioLinkStream(e.target.value)}
                                                        />
                                                    </div>
                                                </Row>

                                                {/* <Row className="mb-3">
                                                    <label htmlFor="image" className="col-md-3 col-form-label">Image radio</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            id="image"
                                                            name="logo"
                                                            // defaultValue={radioData.logo}
                                                            onChange={e => {
                                                                handleFileChangeEdit(e)
                                                                setSelectedImage(e.target.files[0])
                                                            }
                                                            }
                                                        />
                                                    </div>
                                                </Row> */}

                                                {user.role === "Manager" ? (
                                                    <Row className="mb-3">
                                                        <label htmlFor="userType" className="col-md-3 col-form-label">User </label>
                                                        <div className="col-md-9">
                                                            <select
                                                                className="form-control"
                                                                id="userType"
                                                                name="user"
                                                                defaultValue={radioData.user}
                                                                onChange={e => setSelectedUser(e.target.value)}
                                                            >
                                                                <option value="">Select a user</option> {/* Optional default option */}
                                                                {users && Array.isArray(users) && users.map(user => (
                                                                    <option key={user.id} value={user.id}>{user.first_name} {user.last_name}</option>
                                                                ))}

                                                            </select>
                                                        </div>
                                                    </Row>

                                                ) : (<p></p>)}

                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Tag radio </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            defaultValue={radioData.tagRadio}
                                                            className="form-control"
                                                            type="number"
                                                            style={{ border: tagMessageError ? '2px solid red' : '', }}

                                                            id="image"
                                                            name="tagRadio"
                                                            onChange={e => setTagRadio(e.target.value)}
                                                        />
                                                        {tagMessageError &&
                                                            <p style={{ color: 'red' }}> {tagMessageError}</p>
                                                        }
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Simbals </label>
                                                    <div className="col-md-9">
                                                        <input
                                                            defaultValue={radioData.tagSimbals}
                                                            className="form-control"
                                                            type="number"

                                                            id="image"
                                                            name="tagSimbals"
                                                            onChange={e => setTagSimbals(e.target.value)}
                                                        />

                                                    </div>
                                                </Row>


                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Instance </label>
                                                    <div className="col-md-9">
                                                        <select
                                                            className="form-control"
                                                            id="userType"
                                                            name="instance"
                                                            defaultValue={radioData.instance}
                                                            onChange={e => setInstance(e.target.value)}
                                                        >
                                                            <option value="">Choisissez une instance</option>
                                                            <option value="0">Radio screen 01</option> {/* Optional default option */}
                                                            <option value="1">Radio screen 02</option> {/* Optional default option */}


                                                        </select>
                                                    </div>
                                                </Row>


                                                <Row className="mb-3">
                                                    <label htmlFor="userType" className="col-md-3 col-form-label">Instance Simbals</label>
                                                    <div className="col-md-9">
                                                        <select
                                                            className="form-control"
                                                            id="userType"
                                                            name="instanceSimbals"
                                                            defaultValue={radioData.instanceSimbals}
                                                            onChange={e => setInstanceSimbals(e.target.value)}
                                                        >
                                                            <option value="">Choisissez une instance</option>
                                                            <option value="0">Radio screen 01</option> {/* Optional default option */}
                                                            <option value="1">Radio screen 02</option> {/* Optional default option */}

                                                        </select>
                                                    </div>
                                                </Row>

                                                <Row className="mb-3">
                                                    <label htmlFor="allowLyrics" className="col-md-3 col-form-label">Allow Lyrics</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            style={{ marginTop: '8px' }}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="allowLyrics"
                                                            name="allowLyrics"

                                                            checked={allowLyrics}
                                                            onChange={e => setAllowLyrics(e.target.checked)}
                                                        />
                                                    </div>
                                                </Row>
                                                <Row className="mb-3">
                                                    <label htmlFor="logoBehavior" className="col-md-3 col-form-label">Tourner Logo</label>
                                                    <div className="col-md-9">
                                                        <input
                                                            style={{ marginTop: '8px' }}
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="logoBehavior"
                                                            name="logoBehavior"

                                                            checked={logoBehavior}
                                                            onChange={e => setLogoBehavior(e.target.checked)}
                                                        />
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </form>
                                </ModalBody>



                                <ModalFooter>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleEditSubmit(radioId);
                                        }}
                                        className="btn btn-primary waves-effect waves-light"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                    >
                                        <i style={{ fontSize: '17px', marginRight: "5px" }} className="mdi mdi-radio" />
                                        Modifier la radio
                                    </button>
                                </ModalFooter>
                            </Modal>
                        }

                        <Modal isOpen={modal} toggle={toggle}>
                            <ModalHeader toggle={toggle}>Supprimer</ModalHeader>
                            <ModalBody>
                                êtes-vous sûr ? Vous ne pourrez pas annuler cette action ultérieurement.
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={toggle} variant='secondary'>
                                    Annuler
                                </Button>{' '}
                                <Button onClick={() => handleDelete(radioId)} variant='danger' >
                                    Supprimer
                                </Button>
                            </ModalFooter>
                        </Modal>
                    </Card>
                </Col>
            </Row >  </div >
    )
}