import React, { useEffect, useState } from 'react';
import stream from '../../../../assets/images/stream.png';
import live from '../../../../assets/images/live.png';
function StreamNextSong({ nextTitle, configurationRadio, logoRadio }) {
    const [textVisible, setTextVisible] = useState(false); // For the "diffuser sur votre télévision" text
    const [contentVisible, setContentVisible] = useState(false); // For the "À suivre" and image content



    useEffect(() => {
        setTimeout(() => {
            setTextVisible(true);
        }, 5000);

        setTimeout(() => {
            setTextVisible(false);
        }, 10000);


        if (nextTitle.artist) {
            setTimeout(() => {
                setContentVisible(true);
            }, 11000);

            setTimeout(() => {
                setContentVisible(false);
            }, 18000);
        }

    }, []);



    return (
        <div style={{
            position: 'fixed',
            zIndex: 3,
            bottom: '30px',
            right: '30px',
            width: '50%',
            height: configurationRadio?.walletH,
            display: 'flex',
            flexDirection: 'column',
            gap: 20
        }}>
            {/* Diffuser Text */}
            <div style={{

                opacity: textVisible ? 1 : 0,
                // visibility: textVisible ? 'visible' : 'hidden', 
                transform: textVisible ? 'translateY(0)' : 'translateY(100%)',
                transition: 'opacity 2s ease, transform 2s ease',
                height: "100%",
                position: 'absolute', // Position it on top
                top: 0, // Align to top of its container
                left: 0, // Align to the left
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
            }}>
                <div style={{
                    display: 'flex',
                    marginBottom: 10,
                    alignItems: 'center',
                    gap: '15px',
                }}>
                    <h3 style={{
                        fontSize: configurationRadio?.titleSize,
                        fontFamily: configurationRadio?.titlePolice,
                        color: "white", fontWeight: 'bold', margin: 0
                    }}>Diffusez sur votre télévision</h3>
                    <img style={{ width: 50 }} src={stream} />
                    <img style={{ width: 50 }} src={live} />
                </div>

            </div>

            {/* Song Content */}
            <div style={{
                display: contentVisible ? 'flex' : 'none',
                opacity: contentVisible ? 1 : 0,
                transition: 'opacity 2s ease, transform 2s ease',
                transform: contentVisible ? 'translateY(0)' : 'translateY(100%)', // Start from below
                height: "100%",
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '15px',
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', // Center text horizontally
                    height: '100%',
                    padding: '0 10px',
                    whiteSpace: 'nowrap', // Prevents the text from wrapping into multiple lines
                }}>
                    <h4 style={{
                        fontSize: configurationRadio?.titleSize,
                        fontFamily: configurationRadio?.titlePolice,

                        color: "white", fontWeight: 'bold', margin: 0
                    }}>À suivre :</h4>
                </div>
                <div className='song-image' style={{
                    height: "100%",
                    width: configurationRadio?.walletW
                }}>
                    <img className='' alt='' src={nextTitle.album_cover ? nextTitle.album_cover : logoRadio} style={{ height: configurationRadio?.walletH, width: configurationRadio?.walletW, borderRadius: 10 }} />
                </div>
                <div style={{
                    position: 'relative',
                    width: `100%`,
                    height: '100%',
                    left: "15px"
                }}>
                    <div className={`song-title-${configurationRadio?.textEffect}`} style={{
                        position: 'absolute',
                        left: configurationRadio?.titlePosition.includes('left') && 0,
                        bottom: configurationRadio?.titlePosition.includes('bottom') && 0,
                        right: configurationRadio?.titlePosition.includes('right') && 0,
                        transform: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && 'translateY(-50%)',
                        top: (configurationRadio?.titlePosition.includes('center') || configurationRadio?.titlePosition.length === 0) && '50%',
                    }}>
                        <h1 style={{
                            color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.titleColor : 'transparent',
                            fontSize: configurationRadio?.titleSize,
                            fontFamily: configurationRadio?.titlePolice,
                            WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.titleColor}`,
                        }}>{nextTitle.artist}</h1>

                        <span style={{
                            color: ["aucun", "ombre", "elevation"].includes(configurationRadio?.textEffect) ? configurationRadio?.artistColor : 'transparent',
                            fontSize: configurationRadio?.artistSize,
                            fontFamily: configurationRadio?.artistPolice,
                            WebkitTextStroke: configurationRadio?.textEffect === 'contour' && `1px ${configurationRadio?.artistColor}`,
                        }}>{nextTitle.titre}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StreamNextSong;
