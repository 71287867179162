import React, { useState, useEffect } from 'react';

const VideoPlayerWithOverlay = ({
    videoId,
    overlayImage,
    intervalDuration = 30000,
    playerId
}) => {
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setShowOverlay((prev) => !prev);
        }, intervalDuration);

        return () => clearInterval(intervalId);
    }, [intervalDuration]);

    return (
        <div className="relative "
            style={{
                backgroundColor: "#1C1124",
                overflow: 'hidden',
                width: '100%',
                height: '100%'
            }}>
            {/* <iframe
                id="player-container"
                src={`https://www.dailymotion.com/embed/video/${videoId}?autoplay=1`}
                className="w-full h-full"
                frameBorder="0"
                allow="autoplay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: showOverlay ? 0 : 1,
                    transition: 'opacity 0.3s ease-in-out'
                }}
            /> */}

            <div itemscope itemtype="https://schema.org/VideoObject"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: showOverlay ? 0 : 1,
                    transition: 'opacity 0.3s ease-in-out'
                }}            >
                <meta itemprop="name" content="Melody d'Azur" /><meta itemprop="description" content="Melody d'Azur" />
                <meta itemprop="uploadDate" content="2025-01-03T12:24:56.000Z" />
                <meta itemprop="thumbnailUrl" content="https://s2.dmcdn.net/l/Xd5wg1dZv8-tMhQ6v/x240" />
                <meta itemprop="embedUrl" content={`https://geo.dailymotion.com/player/${playerId}.html?video=${videoId}`} />
                <iframe
                    frameborder="0" width="100%" height="100%"
                    style={{
                        width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, overflow: "hidden",
                    }}
                    src={`https://geo.dailymotion.com/player/${playerId}.html?video=${videoId}`}

                    allowfullscreen

                    allow="autoplay; fullscreen; picture-in-picture; web-share"
                    title="Dailymotion video player – Melody d'Azur" />
            </div>

            <div>
                <img
                    src={overlayImage}
                    alt="Overlay"
                    className="absolute top-0 left-0 w-full h-full"
                    style={{
                        objectFit: 'cover',
                        pointerEvents: 'none',
                        opacity: showOverlay ? 1 : 0,
                        transition: 'opacity 0.3s ease-in-out',
                        width: '99%',
                        height: '100%'
                    }}
                />
            </div>

        </div >
    );
};

export default VideoPlayerWithOverlay;